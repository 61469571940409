export interface DisclaimerPage {
    heading: string;
    content: string;
    dateUpdated: string;
}

export function isDisclaimerPage(disclaimerPage: any): disclaimerPage is DisclaimerPage {
    return !!(
        disclaimerPage &&
        typeof disclaimerPage === "object" &&
        typeof disclaimerPage.heading === "string" &&
        typeof disclaimerPage.content === "string" &&
        typeof disclaimerPage.dateUpdated === "string"
    );
}
