import type {User} from "./Users";
import type {Category} from "./Categories";
import {isStatus, Status} from "./Status";

export interface ManualSelection {
    matchup: string;
    market: string;
    selection: string;
}

export interface Signal {
    id: string;
    title: string;
    status: Status;
    datePublished: string;
    dateExpires: string;
    dateCreated: string;
    dateUpdated: string | null;
    author: string | User;
    categories: string[] | Category[];
    confidence: number;
    content: string;
    defaultOdds: number | null;
}

export function isSignal(signal: any): signal is Signal {
    return !!(
        signal &&
        typeof signal === "object" &&
        typeof signal.id === "string" &&
        typeof signal.title === "string" &&
        isStatus(signal.status) &&
        typeof signal.datePublished === "string" &&
        typeof signal.dateExpires === "string" &&
        typeof signal.confidence === "number" &&
        typeof signal.content === "string" &&
        (typeof signal.defaultOdds === "number" || signal.defaultOdds === null) &&
        //relational fields
        //M2O
        "author" in signal &&
        //M2A
        "categories" in signal
    );
}
