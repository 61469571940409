export interface AboutUsPage {
    subheading: string;
    content: string;
    faq: Array<{question: string; answer: string}>;
}

export function isAboutUsPage(aboutUsPage: any): aboutUsPage is AboutUsPage {
    return !!(
        aboutUsPage &&
        typeof aboutUsPage === "object" &&
        typeof aboutUsPage.subheading === "string" &&
        typeof aboutUsPage.content === "string" &&
        Array.isArray(aboutUsPage.faq) &&
        aboutUsPage.faq.every(
            (faqItem: any) =>
                !!faqItem &&
                typeof faqItem === "object" &&
                typeof faqItem.question === "string" &&
                typeof faqItem.answer === "string"
        )
    );
}
