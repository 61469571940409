export interface Settings {
    defaultWebsiteTitle: string;
    defaultMetaDescription: string;
}

export function isSettings(settings: any): settings is Settings {
    return !!(
        settings &&
        typeof settings === "object" &&
        typeof settings.defaultWebsiteTitle === "string" &&
        typeof settings.defaultMetaDescription === "string"
    );
}
