export interface User {
    id: string;
    nickname: string;
    avatar?: string;
    location?: string;
    title?: string;
    description?: string;
    first_name?: string;
    last_name?: string;
}

export function isUser(user: any): user is User {
    return !!(user && typeof user === "object" && typeof user.nickname === "string");
}
