import type {User} from "./Users";
import {isStatus, Status} from "./Status";

export interface Preview {
    id: number;
    status: Status;
    datePublished: string;
    dateUpdated: null | string;
    breadcrumbTitle: string;
    title: string;
    category: string;
    description: string;
    content: string;
    cover: string;
    slug: string;
    author: string | User;
}

export function isPreview(preview: any): preview is Preview {
    return !!(
        preview &&
        typeof preview === "object" &&
        typeof preview.id === "number" &&
        isStatus(preview.status) &&
        typeof preview.datePublished === "string" &&
        (typeof preview.dateUpdated === "string" || preview.dateUpdated === null) &&
        typeof preview.breadcrumbTitle === "string" &&
        typeof preview.title === "string" &&
        typeof preview.category === "string" &&
        typeof preview.description === "string" &&
        typeof preview.content === "string" &&
        typeof preview.cover === "string" &&
        typeof preview.slug === "string" &&
        //relational fields
        "author" in preview
    );
}
