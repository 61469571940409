export interface MessageOfTheDay {
    isVisible: boolean;
    message: string;
    speed: number;
    href: string;
    dateUpdated: null | string;
}

export function isMessageOfTheDay(messageOfTheDay: any): messageOfTheDay is MessageOfTheDay {
    return !!(
        messageOfTheDay &&
        typeof messageOfTheDay === "object" &&
        typeof messageOfTheDay.isVisible === "boolean" &&
        typeof messageOfTheDay.message === "string" &&
        typeof messageOfTheDay.speed === "number" &&
        typeof messageOfTheDay.href === "string" &&
        (typeof messageOfTheDay.dateUpdated === "string" || messageOfTheDay.dateUpdated === null)
    );
}
