export interface TermsAndConditionsPage {
    heading: string;
    content: string;
    dateUpdated: string;
}

export function isTermsAndConditionsPage(
    termsAndConditionsPage: any
): termsAndConditionsPage is TermsAndConditionsPage {
    return !!(
        termsAndConditionsPage &&
        typeof termsAndConditionsPage === "object" &&
        typeof termsAndConditionsPage.heading === "string" &&
        typeof termsAndConditionsPage.content === "string" &&
        typeof termsAndConditionsPage.dateUpdated === "string"
    );
}
