import {Region} from "./Regions";

export interface Bookmaker {
    id: string;
    name: string;
    isEnabled: boolean;
    url: string;
    logoRectangle: string;
    logoSquare: string;
    colors: {
        background: string;
    };
    regions: (number | Region)[];
}

export function isBookmaker(bookmaker: any): bookmaker is Bookmaker {
    return !!(
        bookmaker &&
        typeof bookmaker === "object" &&
        typeof bookmaker.id === "string" &&
        typeof bookmaker.name === "string" &&
        typeof bookmaker.isEnabled === "boolean" &&
        typeof bookmaker.url === "string" &&
        typeof bookmaker.logoRectangle === "string" &&
        typeof bookmaker.logoSquare === "string" &&
        typeof bookmaker.colors === "object" &&
        typeof bookmaker.colors.background == "string" &&
        //relational fields
        "regions" in bookmaker
    );
}
