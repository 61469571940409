export enum CategoryEnum {
    BestSignals = "best-signals",
    AmericanFootball = "american-football",
    Baseball = "baseball",
    Basketball = "basketball",
    IceHockey = "ice-hockey",
    Soccer = "soccer",
    Tennis = "tennis",
    ESport = "e-sport"
}

export interface Category {
    id: CategoryEnum;
    name: string;
}

export function isCategory(category: any): category is Category {
    return !!(
        category &&
        typeof category === "object" &&
        typeof category.id === "string" &&
        Object.values(CategoryEnum).includes(category.id) &&
        typeof category.name === "string"
    );
}
