export interface PrivacyPolicyPage {
    heading: string;
    content: string;
    dateUpdated: string;
}

export function isPrivacyPolicyPage(
    privacyPolicyPage: any
): privacyPolicyPage is PrivacyPolicyPage {
    return !!(
        privacyPolicyPage &&
        typeof privacyPolicyPage === "object" &&
        typeof privacyPolicyPage.heading === "string" &&
        typeof privacyPolicyPage.content === "string" &&
        typeof privacyPolicyPage.dateUpdated === "string"
    );
}
