import type {MessageOfTheDay} from "./MessageOfTheDay";
import type {Post} from "./Posts";
import type {User} from "./Users";
import type {Offer} from "./Offers";
import type {DisclaimerPage} from "./DisclaimerPage";
import type {PrivacyPolicyPage} from "./PrivacyPolicyPage";
import type {TermsAndConditionsPage} from "./TermsAndConditionsPage";
import type {AboutUsPage} from "./AboutUsPage";
import type {Settings} from "./Settings";
import type {Category} from "./Categories";
import type {Signal} from "./Signals";
import type {Bookmaker} from "./Bookmakers";
import type {Preview} from "./Previews";
import type {NewsWidget} from "./NewsWidget";
import type {Country} from "./Countries";
import type {Region} from "./Regions";

export * from "./MessageOfTheDay";
export * from "./Posts";
export * from "./Users";
export * from "./DisclaimerPage";
export * from "./PrivacyPolicyPage";
export * from "./TermsAndConditionsPage";
export * from "./AboutUsPage";
export * from "./Settings";
export * from "./Categories";
export * from "./Signals";
export * from "./Offers";
export * from "./Bookmakers";
export * from "./Previews";
export * from "./NewsWidget";
export * from "./Status";
export * from "./Countries";
export * from "./Regions";

export enum Collection {
    MessageOfTheDay = "messageOfTheDay",
    Posts = "posts",
    Users = "directus_users",
    DisclaimerPage = "disclaimerPage",
    PrivacyPolicyPage = "privacyPolicyPage",
    TermsAndConditionsPage = "termsAndConditionsPage",
    AboutUsPage = "aboutUsPage",
    Settings = "settings",
    Categories = "categories",
    Signals = "signals",
    Offers = "offers",
    Bookmakers = "bookmakers",
    Previews = "previews",
    NewsWidget = "newsWidget",
    Countries = "countries",
    Regions = "regions"
}

export type Collections = {
    [Collection.MessageOfTheDay]: MessageOfTheDay;
    [Collection.Posts]: Post;
    [Collection.Users]: User;
    [Collection.DisclaimerPage]: DisclaimerPage;
    [Collection.PrivacyPolicyPage]: PrivacyPolicyPage;
    [Collection.TermsAndConditionsPage]: TermsAndConditionsPage;
    [Collection.AboutUsPage]: AboutUsPage;
    [Collection.Settings]: Settings;
    [Collection.Categories]: Category;
    [Collection.Signals]: Signal;
    [Collection.Offers]: Offer;
    [Collection.Bookmakers]: Bookmaker;
    [Collection.Previews]: Preview;
    [Collection.NewsWidget]: NewsWidget;
    [Collection.Countries]: Country;
    [Collection.Regions]: Region;
};
