import type {User} from "./Users";
import {isStatus, Status} from "./Status";

export interface Post {
    id: number;
    status: Status;
    datePublished: string;
    dateUpdated: null | string;
    title: string;
    content: string;
    cover: null | string;
    tags: Array<string>;
    metaDescription: string;
    keywords: Array<string>;
    slug: string;
    contentPreview: string;
    author: string | User;
}

export function isPost(post: any): post is Post {
    return !!(
        post &&
        typeof post === "object" &&
        typeof post.id === "number" &&
        isStatus(post.status) &&
        typeof post.datePublished === "string" &&
        (typeof post.dateUpdated === "string" || post.dateUpdated === null) &&
        typeof post.title === "string" &&
        typeof post.content === "string" &&
        typeof post.cover === "string" &&
        Array.isArray(post.tags) &&
        typeof post.metaDescription === "string" &&
        Array.isArray(post.keywords) &&
        typeof post.slug === "string" &&
        typeof post.contentPreview === "string" &&
        //relational fields
        "author" in post
    );
}
