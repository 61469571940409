import {Region} from "./Regions";
import {Bookmaker} from "./Bookmakers";

export interface Offer {
    id: string;
    name: string;
    isEnabled: boolean;
    dateExpires: null | string;
    url: string;
    sneakPeek: string;
    terms: string;
    priority: number;
    action: string;
    logoRectangle: null | string;
    backgroundImage: null | string;
    colors: {
        sneakPeek: string;
        background: string;
    };
    regions: (number | Region)[];
    bookmaker: null | string | Bookmaker;
    notes: null | string;
}

export function isOffer(offer: any): offer is Offer {
    return !!(
        offer &&
        typeof offer === "object" &&
        typeof offer.id === "string" &&
        typeof offer.name === "string" &&
        typeof offer.isEnabled === "boolean" &&
        typeof offer.dateExpires === "string" &&
        typeof offer.url === "string" &&
        typeof offer.sneakPeek === "string" &&
        typeof offer.terms === "string" &&
        typeof offer.priority === "number" &&
        typeof offer.action === "string" &&
        (offer.logoRectangle === null || typeof offer.logoRectangle === "string") &&
        (offer.backgroundImage === null || typeof offer.backgroundImage === "string") &&
        typeof offer.colors === "object" &&
        typeof offer.colors.sneakPeek == "string" &&
        typeof offer.colors.background == "string" &&
        //relational fields
        "regions" in offer &&
        "bookmaker" in offer
    );
}
